import { WebshopMetricsStateModel } from '../state/webshop-metrics.state';

export const webshopMetricsDefaults: WebshopMetricsStateModel = {
  loading: true,
  date: null,
  serviceLevel: 0,
  previousServiceLevel: 0,
  serviceLevelDiff: 0,
  inventoryValue: 0,
  turnoverTime: 0,
  deadstockValue: 0,
  inFullLevel: null,
  previousInFullLevel: null,
  inFullLevelDiff: 0,
  onTimeLevel: null,
  previousOnTimeLevel: null,
  onTimeLevelDiff: 0,
};
